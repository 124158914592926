import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InvoicesNavModule } from './extensions/dbd5495e84289aa01f131234a6f107e829aecc4633be7d79edfb1ae3b47b0f06/invoices-nav.module';

import SharedProviders_0_0 from './extensions/dbd5495e84289aa01f131234a6f107e829aecc4633be7d79edfb1ae3b47b0f06/providers';


@NgModule({
    imports: [CommonModule, InvoicesNavModule],
    providers: [...SharedProviders_0_0],
})
export class SharedExtensionsModule {}
